.Ellipsis {
    margin-left: 50px;
    font-size: 18px;
    cursor: pointer;
}

/* Scope styles to the sidebar menu only */
.sidebar-menu .ant-menu-item {
    padding: 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
}

.sidebar-menu .ant-menu-item-selected {
    background-color: #e6f7ff !important;
}

.sidebar-menu .ant-dropdown-trigger {
    font-size: 18px;
    margin-left: auto;
    flex-shrink: 0;
    cursor: pointer;
}

/* Hide ellipsis by default */
.menu-item .ellipsis-wrapper {
    display: none; /* Hidden unless hovered or selected */
}

/* Show ellipsis on hover */
.menu-item:hover .ellipsis-wrapper {
    display: block; /* Visible on hover */
}

/* Show ellipsis for selected chat */
.menu-item.selected .ellipsis-wrapper {
    display: block; /* Visible for the selected chat */
}