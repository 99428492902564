.edit-element {
  padding: 24px;
  margin-top: 70px; /* Adjust this value as needed */
}


.edit-element h1 {
    font-size: 24px;
    font-weight: normal;
    margin-left: 10px;
}

.edit-element-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure the header items are spaced properly */
    position: relative; /* Position the header relative to its normal flow */
    top: 20px; /* Push the header down without affecting the rest of the layout */
}

.edit-element-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px; /* Align with the back button */
}

.unsaved-changes-indicator {
    color: #808080;
    font-size: 16px;
    margin-left: 20px;
}

.edit-element-back-button-container {
    position: absolute;
    top: 20px;
    left: 20px;
}

.edit-element-back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000;
}


.edit-element-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 60px;
}

.visualization-container {
    flex-grow: 1;
    height: 55vh;
    width: 50%;
    margin-bottom: 20px;
    background: #fff;
    padding: 20px;
    border: solid 1px #dddddd;
    border-radius: 7px;
}

.chart-controls {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 200px;
    max-width: 300px;
    margin-top: 20px;
}

.edit-element-back-button-container {
    margin-top: 90px;
}

.edit-element-sidebar {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 7px;
    height: 55vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Adds space between the form controls */
}

.edit-element-sidebar label {
    display: block;
    margin-bottom: 10px; /* Add some space between each label/input pair */
}

.edit-element-sidebar label > input,
.edit-element-sidebar label > select {
    margin-left: 10px; /* Space between label text and input/select field */
}

.edit-element-sidebar input[type="number"] {
    width: 50px; /* Set the desired width */
}


.edit-element-sidebar input[type="text"],
.edit-element-sidebar input[type="number"] {
    height: 25px; /* Adjust the height to match your input fields */
    padding: 5px; /* Adjust padding as necessary */
    border-radius: 4px; /* Keep the border radius consistent */
    border: 1px solid #ccc; /* Consistent border style */
    font-size: 14px; /* Ensure font size is consistent */
}

.edit-element-sidebar select {
    height: 35px; /* Adjust the height to match your input fields */
    padding: 5px; /* Adjust padding as necessary */
    border-radius: 4px; /* Keep the border radius consistent */
    border: 1px solid #ccc; /* Consistent border style */
    font-size: 14px; /* Ensure font size is consistent */
}

.saved-changes-indicator-element {
    color: #808080;
    font-size: 16px;
    margin-left: 20px;
}

/* Add this to your EditElement.css file */

/* Container for the color input and dropdown */
.color-input-container {
    display: flex;
    align-items: center;
    gap: 5px; /* Add some space between the color input and the dropdown */
}

.color-input {
    border: 1px solid #ccc;
    height: 30px;
    width: 43px;
    padding: 0;
    margin-right: 8px;

}

.color-dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.color-dropdown-button:focus {
    outline: none;
}

.color-dropdown-button:hover {
    border-color: #888; /* Add hover effect for the dropdown button */
}

.color-picker-dropdown {
    margin-top: 10px;
    padding: 5px;
}

.dropdown-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg); /* Rotate the arrow 90 degrees to point downward */
}

.color-picker-row {
    display: flex;
    align-items: center;
}

.color-picker-dropdown label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.reconfigure-interface {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the input section horizontally */
    margin-top: 10px; /* Reduce the margin at the top to remove whitespace */
}

.reconfigure-query-input {
    width: 64.1%; /* Reduce the width of the input field */
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #dddddd;
    margin-right: 10px;
    font-size: 14px; /* Adjust font size */
}

.chat-input-section-edit-element {
    display: flex;
    width: 84.5%; /* Ensure it doesn't take unnecessary space */
}


.chat-input-section-edit-element button:hover {
    background-color: #9c9c9c;
}


.chat-interface-reconfigure-interface {
    margin-top: 0px;
}
