/* Page Container */
.archived-chats-page {
  padding: 24px;
  margin-top: 70px;
}

/* Page Title */
.page-title {
  margin: 0;
}

