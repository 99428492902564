/* General Page Styling */
.user-access-page {
  padding: 24px;
  margin-top: 70px; /* Adjust for fixed navbar height */
}


/* Page Title */
.user-access-page .page-title {
  margin: 0;
}


/* Table Styling */
.user-access-page .user-access-table {
  margin-top: 16px;
}


.user-access-page .back-button-container {
  display: flex;
  justify-content: flex-start; /* Align to the left */
}

.user-access-page .back-button-container .ant-btn {
  padding-left: 0; /* Optional: Adjust padding for tighter alignment */
}

/* UserAccessPage.css */
.user-access-page .ant-card-body {
  /* Custom styles for the card body on this page only */
  padding: 10px;
  background-color: #f0f2f5;
}