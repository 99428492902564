/* Dashboards Page */
.charts-page {
  padding: 24px;
  margin-top: 70px; /* Align with the navbar */
}


.charts-page .card-style .ant-card-actions {
  opacity: 0; /* Hide actions by default */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.charts-page .card-style:hover .ant-card-actions {
  opacity: 1; /* Show actions on hover */
}

.charts-page .card-style .ant-card-head {
  border-bottom: none;
}


  /* Shrink body padding to reduce height */
.charts-page .ant-card-body {
    height: 90px;
  }

.charts-page .ant-card-head-title {
  font-weight: normal; /* or 400, etc. */
}

.ant-card {
  box-shadow: none;
}

.card-style {
  /* Set a fixed width or a max-width */
  width: 350px; /* or use max-width, etc. */
}

.charts-page .card-style .ant-card-body {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* anchor everything at the top */
  padding: 8px 24px;
}