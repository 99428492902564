/* General page layout */
.schema-page {
  padding: 24px;
  margin-top: 70px; /* Adjust this value as needed */
}

.scrollable-dropdown {
  max-height: 200px; /* Limit the dropdown height */
  overflow-y: auto; /* Enable vertical scrolling */
}
