.settings-page {
  padding: 24px;
  margin-top: 70px; /* Adjust for fixed navbar height */
}

.settings-item {
  cursor: pointer;
}

.settings-item:hover {
  background-color: #f5f5f5;
}

/* Modal Styling */
.card-style {
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
