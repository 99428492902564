/* Global Font */
body {
  font-family: 'Poppins', sans-serif;
}

.my-data-sources-page {
  padding: 24px;
  margin-top: 70px; /* Adjust this value as needed */
}


/* Data Source Logo */
.data-source-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #e0e0e0; /* Original border */
}

/* Modal-specific Data Source Logo */
.modal-data-source-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  border: none; /* Remove border */
}

/* List Item Hover Effect */
.ant-list-item {
  transition: background-color 0.3s;
}

.ant-list-item:hover {
  background-color: #f5f5f5;
}

/* Success Icon in Modal */
.success-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  object-fit: contain;
}

/* Success Modal Content Centering */
.success-modal-content {
  text-align: center;
}

/* Loading Screen */
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0;
}

/* Modal Styling */
.add-data-source-modal .ant-modal-content {
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.add-data-source-modal .ant-modal-header {
  border-bottom: none; /* Remove bottom border of header */
}

.add-data-source-modal .ant-modal-title {
  font-weight: normal; /* Make title font weight normal */
}

.add-data-source-modal .ant-modal-body {
  font-weight: normal; /* Ensure body text is normal */
}

/* Additional Spacing for Form Items */
.ant-form-item {
  margin-bottom: 16px;
}
