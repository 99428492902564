@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Override Ant Design's default font */
.ant-typography,
.ant-form-item-label > label,
.ant-form-item-control-input-content,
.ant-btn {
  font-family: 'Poppins', sans-serif;
}


@tailwind base;
@tailwind components;
@tailwind utilities;