.navbar {
  display: flex;
  justify-content: space-between; /* Ensures logo and menu are on opposite sides */
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  height: 64px; /* Ant Design's default header height */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid #e0e0e0; /* Light grey border */
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-menu {
  display: flex;
  justify-content: flex-end; /* Aligns the menu items to the right */
  flex: 1; /* Pushes the menu items to the right */
}

.ant-menu {
  line-height: 64px; /* Align menu items vertically */
  background: none; /* Remove background for the menu */
  border-bottom: none; /* Remove bottom border of the menu */
}

.ant-menu-item-selected {
  background-color: rgba(24, 144, 255, 0.1); /* Light primary color */
}

.navbar-logo .ant-image {
  display: flex !important; /* Override Ant Design's inline-block */
  align-items: center; /* Ensures vertical alignment */
}
